// Business flags
export const LENDER_ESIGN = "lender-esign";
export const PROOF_TRANSACTIONS = "proof-transactions";
export const SIGN_TRANSACTIONS = "sign-transactions";
export const SIGN_AHEAD = "sign_ahead";
export const STOP_SIGNER_AUTO_CHARGE = "stop-signer-auto-charge";
export const TRANSACTION_LEVEL_PAYER_CONFIGURATION = "transaction-level-payer-configuration";
export const MIXED_SIGNING_UI_SUPPORT = "mixed-signing-ui-support";
export const HYBRID_REQUIRES_ACCOUNT = "hybrid-portal-requires-account";
export const HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN = "hide-esign-from-new-transaction-dropdown";
export const HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN =
  "hide-notarization-from-new-transaction-dropdown";
export const BUSINESS_TEMPLATE_NEW_FEATURE = "new-feature-business-templates-popup";
export const IA_NAV = "ia-nav";
export const MOBILE_BUSINESS_PORTAL = "mobile-business-portal";
export const REFERRAL_CAMPAIGNS_P2 = "referral-campaigns-p2";
export const SELF_SERVE_FORCE_COMPLETE = "self-serve-force-complete";
export const MULTISIGNER_EASYLINK = "multisigner-easylink";
export const LOAN_MOD_BORROWER = "loan-mod-borrower";
export const TRANSACTION_CREATION_V3_SET_SAME_ACTIVATION_EXPIRY =
  "transaction-creation-v3-set-same-activation-expiry";
export const SIGNING_ORDER_UI_SUPPORT = "signing-order-ui-support";
export const IDENTIFY_TRANSACTIONS = "identify-transaction";
// BIZ-7064 remove this + all instances
export const REPLACE_DOC = "replace-document";

export const SENDER_ROLE_PHASE_3 = "sender-role-phase-3";
export const PS1583_ATTESTATION = "ps1583-attestation";
export const HIDE_GET_STARTED = "hide-get-started";

export const TXN_DETAILS_REDESIGN_BIZ = "txn_details_redesign_biz";
export const MOVE_TOOLS = "move-tools";

// Real Estate Flags
export const ENABLE_ENOTES_IN_HYBRIDS = "enable-enotes-in-hybrids";
export const NOTARY_ROUTING_RULES = "notary-routing-rules-check";
export const REAL_ESTATE_ESIGN = "real-estate-esign";
export const REAL_ESTATE_PROOF = "real-estate-proof";
export const HYBRID_ESIGN_COMBINATION = "hybrid-esign-combination";

export const TXN_DETAILS_REDESIGN_TITLE = "txn_details_redesign_title";
export const TXN_DETAILS_REDESIGN_LENDER = "txn_details_redesign_lender";

// Signer Flags
export const USER_DASHBOARD_RESKIN = "user-dashboard-reskin";
export const USER_SETTINGS_PRIVACY_TAB = "user-settings-privacy-tab";
export const TIMEOUT_MODAL = "timeout_modal";

// Org Flags
export const REAL_ESTATE_COLLAB_ENABLED = "realEstateCollabEnabled";
export const NON_ONBOARDED_TITLE_ENABLED = "nonOnboardedTitleEnabled";
export const RECIPIENT_GROUPS_UI = "signing-groups-ui";

// Keystone flags
export const TXN_DETAILS_REDESIGN_KEYSTONE = "txn_details_redesign";

// Command Center flags
export const COMMAND_CENTER_ACCESS_CONTROLS = "command-center-access-controls";
export const COMMAND_CENTER_ORG_DETAILS = "command-center-org-details";
