import { defineMessages } from "react-intl";
import { isAfter } from "date-fns";

import {
  type ProofRequirementField,
  ProofRequirementFormValues,
} from "common/proof_requirements/common";
import {
  type AddressType,
  DocumentRequirementEnum,
  OrganizationTransactionContactRoleType,
  OrganizationTransactionVariant,
  type Payer,
  type PhoneType,
  SigningRequirementEnum,
  Tier,
  VestingTypesEnum,
} from "graphql_globals";
import { type SimpleTransactionOrganization } from "common/transaction_creation/v3/form/simple_transaction_organization_fragment.graphql";
import {
  ALL_LENDER_TRANSACTION_TYPES,
  LENDER_HYBRID_TRANSACTION_TYPES,
  TITLE_MORTGAGE_TRANSACTION_TYPES,
  TRANSACTION_TYPE_HELOC,
  TRANSACTION_TYPE_HYBRID_TRAILING_DOCS,
  TRANSACTION_TYPE_LOAN_MOD_BORROWER,
  TRANSACTION_TYPE_OTHER,
  TRANSACTION_TYPE_TRAILING_DOCS,
} from "constants/transaction";
import type { Address } from "common/core/form/address";
import { SUPPORTED_FILE_EXTENSIONS } from "common/document/uploader/document_item_util";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { useSignTransactionsEnabled } from "util/feature_detection";

import { useSimpleTransactionCreationV3 } from "../detection";
import type { CustomFeesType } from "../sections/nst_payment";

export type FieldConfig<Value = unknown> = {
  display: "editable" | "hidden" | "readonly";
  default: Value | undefined;
  required: boolean;
};

const ESIGN_SUPPORTED_FILE_EXTENSIONS = ["PDF", "DOCX"];

const DEFAULT_PROOF_REQUIREMENTS: ProofRequirementFormValues[] = [
  ProofRequirementFormValues.IAL2,
  ProofRequirementFormValues.SMS,
  ProofRequirementFormValues.KBA,
];

type DocumentConfig = {
  value: boolean;
  // Locked values cannot be modified but differ based on config type
  locked: boolean;
};

type FieldConfigs = {
  transactionName: FieldConfig;
  transactionType: FieldConfig;
  titleUnderwriter: FieldConfig;
  propertyAddress: FieldConfig<AddressType>;
  recordingLocation: FieldConfig;
  fileNumber: FieldConfig;
  loanNumber: FieldConfig;
  externalId: FieldConfig;
  emailSubject: FieldConfig<string>;
  emailMessage: FieldConfig<string>;
  emailSignature: FieldConfig<string>;
  signerMessage: FieldConfig<string>;
  promissoryNote: FieldConfig;
  payer: FieldConfig<Payer.ORGANIZATION | Payer.CUSTOMER>;
  orgRequiredPayment: FieldConfig<Payer.ORGANIZATION | Payer.CUSTOMER>;
  secondaryIdRequired: FieldConfig;
  ccRecipientEmail: FieldConfig<string>;
  // Need to revisit configs for fields that are arrays such as signers and contacts because we'll need more flexibility
  // than just one config that applies to every signer/contact (e.g. signer options, primary collaborator). requiredOverride
  // is in place as a temporary workaround.
  signerCountryCode: FieldConfig<PhoneType["countryCode"]>;
  signerPhoneNumber: FieldConfig<PhoneType["number"]>;
  signerFirstName: FieldConfig<string>;
  signerMiddleName: FieldConfig<string>;
  signerLastName: FieldConfig<string>;
  signerEmail: FieldConfig<string>;
  signerSigningRequirement: FieldConfig<SigningRequirementEnum>;
  signerAddress: FieldConfig<AddressType>;
  signerProofRequirements: FieldConfig<ProofRequirementField>;
  signerSharedInboxEmail: FieldConfig<string>;
  signerVestingType: FieldConfig<VestingTypesEnum>;
  signingOrderEnabled: FieldConfig<boolean>;
  smsAuthRequired: FieldConfig<boolean>;
  contactPhoneNumber: FieldConfig<string>;
  contactFirstName: FieldConfig<string>;
  contactLastName: FieldConfig<string>;
  contactEmail: FieldConfig<string>;
  contactRole: FieldConfig<OrganizationTransactionContactRoleType>;
  contactTitle: FieldConfig<string>;
  contactOrganizationName: FieldConfig<string>;
  contactOrganizationAddress: FieldConfig<Address>;
  contactShownToSigner: FieldConfig<boolean>;
  contactAccessToTransaction: FieldConfig<boolean>;
  // Under the hood, collaborators and contacts are the same entity. Collaborators are just one of three roles when real
  // estate collab is enabled. Collaborators just have three fields that differ from contacts between configs, highlighted here.
  // Otherwise all other attributes the same.
  collaboratorRole: FieldConfig<OrganizationTransactionContactRoleType>;
  collaboratorShownToSigner: FieldConfig<boolean>;
  collaboratorAccessToTransaction: FieldConfig<boolean>;
  titleAgency: FieldConfig;
  titleAgencyName: FieldConfig;
  nstPayer: FieldConfig;
  customFees: FieldConfig<CustomFeesType>;
  activationDate: FieldConfig;
  expirationDate: FieldConfig;
  notaryMeetingTime: FieldConfig<string>;
  notaryMeetingDate: FieldConfig;
  closerAssigneeId: FieldConfig<string>;
  notarizeCloserOverride: FieldConfig<boolean>;
  credibleWitnessPhoneNumber: FieldConfig<PhoneType["number"]>;
  credibleWitnessFirstName: FieldConfig<string>;
  credibleWitnessMiddleName: FieldConfig<string>;
  credibleWitnessLastName: FieldConfig<string>;
  credibleWitnessEmail: FieldConfig<string>;
  notaryNotes: FieldConfig;
  personallyKnownToNotary: FieldConfig<boolean>;
  documentUploader: FieldConfig; // TODO: Add type
};

type SectionConfigs = {
  canAddRepresentativeSigner: boolean;
  canAddRecipientGroup: boolean;
  canCollectNotaryPayment: boolean;
  canAddCollaborators: boolean;
  isCollaboratorUser: boolean;
  hasDefaultContact: boolean;
  optionalProofSigner: boolean;
  requireProofSigner: boolean;
  // Document section configs
  defaultDocPermissions: Record<"witnessRequired", boolean>;
  defaultDocRequirement:
    | DocumentRequirementEnum.ESIGN
    | DocumentRequirementEnum.NOTARIZATION
    | DocumentRequirementEnum.CERTIFY
    | undefined;
  canSetDocPermissions: DocumentConfig;
  canSetDocRequirements: DocumentConfig;
  canRequireAttestation: DocumentConfig;
  validateDuplicateEmails: boolean;
  splitBookmarkedPdf: DocumentConfig;
  supportedFileTypes: typeof SUPPORTED_FILE_EXTENSIONS;
  canRequireWitness: DocumentConfig;
  placeOrderCapable: boolean;
  // TODO BIZ-6946
  maxSigners?: number;
  transactionProofRequirements: ProofRequirementFormValues[];
};

export type ConfiguredField = keyof FieldConfigs;
export type SectionConfig = keyof SectionConfigs;
export type ValidationsConfig = {
  validateOrgPayment?: boolean;
  validateDocuments?: boolean;
  validateDocumentSignatures?: boolean;
  validateNSTCompliance?: boolean;
  validateRecipientDetails?: boolean;
  validateStateEligibility?: boolean;
  validateENote?: boolean;
};

export type Config = {
  id: string;
  name: { id: string; defaultMessage: string };
  description?: { id: string; defaultMessage: string } | null;
  validations: ValidationsConfig;
} & FieldConfigs &
  SectionConfigs;

const MESSAGES = defineMessages({
  esignName: { id: "b49d7e3d-f55d-4cbf-8122-abea1db00fd4", defaultMessage: "eSign" },
  signName: { id: "f01e425d-9756-45cf-8e43-0fb70cfc6c1f", defaultMessage: "Sign" },
  esignDescription: {
    id: "0956ea45-9a36-4c1a-8dd4-7951aecb60f3",
    defaultMessage: "Digital signatures without identity verification or notarization",
  },
  signDescription: {
    id: "23b30ad6-ee71-4bf8-9326-1863d927a335",
    defaultMessage: "Send documents for signature",
  },
  certifyName: { id: "6ba66813-1f06-4270-9c24-f624aad5588d", defaultMessage: "Certify" },
  certifyDescription: {
    id: "572b57b5-4e99-4509-9062-16705d9babc1",
    defaultMessage: "Certify the authenticity of documents, records and content",
  },
  identifyName: { id: "e41a5a79-7ca8-4b6a-842d-f96efc1df1dd", defaultMessage: "Identify" },
  identifyDescription: {
    id: "d4a327de-1430-4469-b93e-4bfc02183c23",
    defaultMessage: "Secure identity verification without documents",
  },
  proofName: { id: "e2a4aaa5-5b46-4dfd-972e-a68865a7997c", defaultMessage: "Proof" },
  proofDescription: {
    id: "f685f71e-ca87-4f83-9f08-b2c1519487f7",
    defaultMessage: "Digital signatures with identity verification",
  },
  notarizationName: { id: "ae4058e7-66cf-4e00-a09f-de381328a873", defaultMessage: "Notarization" },
  notarizationDescription: {
    id: "7d0def1f-3c0e-40b2-a696-1c97dfe5b758",
    defaultMessage: "Digital signatures with notarization",
  },
  refinanceName: { id: "077c2fe3-fbff-495e-857e-b7eaf19e1545", defaultMessage: "Refinance" },
  hybridRefinanceName: {
    id: "b33335d3-2ce1-4a81-9c79-0d92a684e175",
    defaultMessage: "Hybrid Refinance",
  },
  purchaseBuyerLoanName: {
    id: "4bcb089d-1a3c-46f1-8a81-c454ebbbe6d3",
    defaultMessage: "Purchase - Buyer",
  },
  hybridPurchaseBuyerName: {
    id: "5a64ff1b-b54e-4dde-95f1-d9ba0b11aaca",
    defaultMessage: "Hybrid Purchase - Buyer",
  },
  trailingDocsName: {
    id: "4f68da57-db85-40e4-a0bf-a167faa794d2",
    defaultMessage: "Trailing documents",
  },
  hybridTrailingDocsName: {
    id: "c5a8267c-34f4-4747-806f-bd3a4090bcad",
    defaultMessage: "Hybrid - Trailing documents",
  },
  helocName: {
    id: "4f68da57-db85-40e4-a0bf-a167faa794d2",
    defaultMessage: "HELOC",
  },
  loanModificationLenderName: {
    id: "4f68da57-db85-40e4-a0bf-a167faa794d2",
    defaultMessage: "Loan modification (borrower)",
  },
  loanModificationTitleName: {
    id: "4f68da57-db85-40e4-a0bf-a167faa794d2",
    defaultMessage: "Loan modification (borrower)",
  },
  otherName: {
    id: "4f68da57-db85-40e4-a0bf-a167faa794d2",
    defaultMessage: "Other",
  },
  purchaseSellerName: {
    id: "4f68da57-db85-40e4-a0bf-a167faa794d2",
    defaultMessage: "eClose - Seller",
  },
  purchaseBuyerCashName: {
    id: "4f68da57-db85-40e4-a0bf-a167faa794d2",
    defaultMessage: "eClose - Cash",
  },
});

const SIGN_CONFIG: Config = {
  id: "sign",
  name: MESSAGES.signName,
  description: MESSAGES.signDescription,
  validations: {
    validateOrgPayment: true,
    validateDocuments: true,
    validateDocumentSignatures: true,
  },
  transactionName: {
    display: "editable",
    default: "",
    required: false,
  },
  transactionType: {
    display: "editable",
    default: "",
    required: false,
  },

  titleUnderwriter: {
    display: "hidden",
    default: "",
    required: false,
  },
  propertyAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  recordingLocation: {
    display: "hidden",
    default: "",
    required: false,
  },
  fileNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  loanNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  externalId: {
    display: "editable",
    default: "",
    required: false,
  },
  emailSubject: {
    display: "editable",
    default: "",
    required: false,
  },
  emailMessage: {
    display: "editable",
    default: "",
    required: false,
  },
  emailSignature: {
    display: "editable",
    default: "",
    required: false,
  },
  signerMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  activationDate: {
    display: "editable",
    default: undefined,
    required: false,
  },
  expirationDate: {
    display: "editable",
    default: undefined,
    required: false,
  },
  notaryMeetingTime: {
    display: "hidden",
    default: "",
    required: false,
  },
  notaryMeetingDate: {
    display: "hidden",
    default: "",
    required: false,
  },
  closerAssigneeId: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  notarizeCloserOverride: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  credibleWitnessFirstName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessMiddleName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessLastName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessPhoneNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  notaryNotes: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  personallyKnownToNotary: {
    display: "hidden",
    default: false,
    required: false,
  },
  payer: {
    display: "editable",
    default: undefined,
    required: false,
  },
  promissoryNote: {
    display: "hidden",
    default: "",
    required: false,
  },
  secondaryIdRequired: {
    display: "hidden",
    default: "",
    required: false,
  },
  ccRecipientEmail: {
    display: "editable",
    default: "",
    required: false,
  },
  signerFirstName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerMiddleName: {
    display: "editable",
    default: "",
    required: false,
  },
  signerLastName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerEmail: {
    display: "editable",
    default: "",
    required: true,
  },
  signerSigningRequirement: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  signerCountryCode: {
    display: "editable",
    default: "",
    required: false,
  },
  signerPhoneNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  signerAddress: {
    display: "editable",
    default: undefined,
    required: false,
  },
  signerProofRequirements: {
    display: "editable",
    default: [],
    required: false,
  },
  signerSharedInboxEmail: {
    display: "editable",
    default: "",
    required: true,
  },
  signerVestingType: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  requireProofSigner: false,
  optionalProofSigner: true,
  smsAuthRequired: {
    display: "hidden",
    default: false,
    required: false,
  },
  signingOrderEnabled: {
    display: "editable",
    default: false,
    required: false,
  },
  canAddRepresentativeSigner: true,
  canAddRecipientGroup: true,
  canCollectNotaryPayment: true,
  contactPhoneNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactFirstName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactLastName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactRole: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  collaboratorRole: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  contactTitle: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactOrganizationName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactOrganizationAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  contactShownToSigner: {
    display: "hidden",
    default: false,
    required: false,
  },
  contactAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  collaboratorShownToSigner: {
    display: "hidden",
    default: false,
    required: false,
  },
  collaboratorAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  titleAgency: {
    display: "hidden",
    default: "",
    required: false,
  },
  canAddCollaborators: false,
  hasDefaultContact: false,
  nstPayer: {
    display: "hidden",
    default: "",
    required: false,
  },
  customFees: {
    display: "hidden",
    default: {},
    required: false,
  },
  documentUploader: {
    display: "editable",
    default: "",
    required: true,
  },
  titleAgencyName: {
    display: "hidden",
    default: "",
    required: false,
  },
  orgRequiredPayment: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  defaultDocPermissions: { witnessRequired: false },
  defaultDocRequirement: DocumentRequirementEnum.ESIGN,
  canSetDocPermissions: { value: true, locked: true },
  canSetDocRequirements: { value: false, locked: true },
  canRequireAttestation: { value: false, locked: false },
  validateDuplicateEmails: true,
  splitBookmarkedPdf: { value: false, locked: false },
  supportedFileTypes: ESIGN_SUPPORTED_FILE_EXTENSIONS,
  transactionProofRequirements: [
    ProofRequirementFormValues.IAL2,
    ProofRequirementFormValues.CA_SELFIE,
    ProofRequirementFormValues.KBA,
    ProofRequirementFormValues.SMS,
  ],
  canRequireWitness: { value: false, locked: true },
  placeOrderCapable: false,
  isCollaboratorUser: false,
};

const CERTIFY_CONFIG: Config = {
  id: "certify",
  name: MESSAGES.certifyName,
  description: MESSAGES.certifyDescription,
  validations: {
    validateOrgPayment: false,
    validateDocuments: true,
    validateDocumentSignatures: false,
  },
  transactionName: {
    display: "editable",
    default: "",
    required: false,
  },
  transactionType: {
    display: "editable",
    default: "",
    required: false,
  },
  titleUnderwriter: {
    display: "hidden",
    default: "",
    required: false,
  },
  propertyAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  recordingLocation: {
    display: "hidden",
    default: "",
    required: false,
  },
  fileNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  loanNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  externalId: {
    display: "editable",
    default: "",
    required: false,
  },
  emailSubject: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailSignature: {
    display: "hidden",
    default: "",
    required: false,
  },
  signerMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  activationDate: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  expirationDate: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  notaryMeetingTime: {
    display: "hidden",
    default: "",
    required: false,
  },
  notaryMeetingDate: {
    display: "hidden",
    default: "",
    required: false,
  },
  closerAssigneeId: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  notarizeCloserOverride: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  credibleWitnessFirstName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessMiddleName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessLastName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessPhoneNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  notaryNotes: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  personallyKnownToNotary: {
    display: "hidden",
    default: false,
    required: false,
  },
  payer: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  orgRequiredPayment: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  promissoryNote: {
    display: "hidden",
    default: "",
    required: false,
  },
  secondaryIdRequired: {
    display: "hidden",
    default: "",
    required: false,
  },
  ccRecipientEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  signerFirstName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerMiddleName: {
    display: "editable",
    default: "",
    required: false,
  },
  signerLastName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerEmail: {
    display: "readonly",
    default: "",
    required: true,
  },
  signerSigningRequirement: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  signerCountryCode: {
    display: "editable",
    default: "",
    required: false,
  },
  signerPhoneNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  signerAddress: {
    display: "editable",
    default: undefined,
    required: false,
  },
  signerProofRequirements: {
    display: "hidden",
    default: [],
    required: false,
  },
  signerSharedInboxEmail: {
    display: "editable",
    default: "",
    required: true,
  },
  signerVestingType: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  requireProofSigner: false,
  optionalProofSigner: false,
  smsAuthRequired: {
    display: "hidden",
    default: false,
    required: false,
  },
  signingOrderEnabled: {
    display: "editable",
    default: false,
    required: false,
  },
  canAddRepresentativeSigner: false,
  canAddRecipientGroup: true,
  canCollectNotaryPayment: true,
  contactPhoneNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactFirstName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactLastName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactRole: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  collaboratorRole: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  contactTitle: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactOrganizationName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactOrganizationAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  contactShownToSigner: {
    display: "hidden",
    default: false,
    required: false,
  },
  contactAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  collaboratorShownToSigner: {
    display: "hidden",
    default: false,
    required: false,
  },
  collaboratorAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  titleAgency: {
    display: "hidden",
    default: "",
    required: false,
  },
  canAddCollaborators: false,
  hasDefaultContact: false,
  nstPayer: {
    display: "hidden",
    default: "",
    required: false,
  },
  customFees: {
    display: "hidden",
    default: {},
    required: false,
  },
  documentUploader: {
    display: "editable",
    default: "",
    required: true,
  },
  titleAgencyName: {
    display: "hidden",
    default: "",
    required: false,
  },
  defaultDocPermissions: { witnessRequired: false },
  defaultDocRequirement: DocumentRequirementEnum.CERTIFY,
  canSetDocPermissions: { value: true, locked: true },
  canSetDocRequirements: { value: false, locked: true },
  canRequireAttestation: { value: false, locked: false },
  validateDuplicateEmails: true,
  splitBookmarkedPdf: { value: false, locked: false },
  supportedFileTypes: ESIGN_SUPPORTED_FILE_EXTENSIONS,
  transactionProofRequirements: [],
  canRequireWitness: { value: false, locked: true },
  placeOrderCapable: false,
  isCollaboratorUser: false,
};

const IDENTIFY_CONFIG: Config = {
  id: "identify",
  name: MESSAGES.identifyName,
  description: MESSAGES.identifyDescription,
  validations: {
    validateOrgPayment: true,
  },
  transactionName: {
    display: "editable",
    default: "",
    required: false,
  },
  transactionType: {
    display: "editable",
    default: "",
    required: false,
  },
  titleUnderwriter: {
    display: "hidden",
    default: "",
    required: false,
  },
  propertyAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  recordingLocation: {
    display: "hidden",
    default: "",
    required: false,
  },
  fileNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  loanNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  externalId: {
    display: "editable",
    default: "",
    required: false,
  },
  emailSubject: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailSignature: {
    display: "hidden",
    default: "",
    required: false,
  },
  signerMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  activationDate: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  expirationDate: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  notaryMeetingTime: {
    display: "hidden",
    default: "",
    required: false,
  },
  notaryMeetingDate: {
    display: "hidden",
    default: "",
    required: false,
  },
  closerAssigneeId: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  notarizeCloserOverride: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  credibleWitnessFirstName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessMiddleName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessLastName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessPhoneNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  notaryNotes: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  personallyKnownToNotary: {
    display: "hidden",
    default: false,
    required: false,
  },
  payer: {
    display: "hidden",
    default: undefined,
    required: true,
  },
  orgRequiredPayment: {
    display: "editable",
    default: undefined,
    required: true,
  },
  promissoryNote: {
    display: "hidden",
    default: "",
    required: false,
  },
  secondaryIdRequired: {
    display: "hidden",
    default: "",
    required: false,
  },
  ccRecipientEmail: {
    display: "editable",
    default: "",
    required: false,
  },
  signerFirstName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerMiddleName: {
    display: "editable",
    default: "",
    required: false,
  },
  signerLastName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerEmail: {
    display: "editable",
    default: "",
    required: true,
  },
  signerSigningRequirement: {
    display: "hidden",
    default: SigningRequirementEnum.IDENTIFY,
    required: false,
  },
  signerCountryCode: {
    display: "editable",
    default: "",
    required: false,
  },
  signerPhoneNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  signerAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  signerProofRequirements: {
    display: "editable",
    default: [],
    required: true,
  },
  signerSharedInboxEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  signerVestingType: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  requireProofSigner: true,
  optionalProofSigner: false,
  smsAuthRequired: {
    display: "hidden",
    default: false,
    required: false,
  },
  signingOrderEnabled: {
    display: "hidden",
    default: false,
    required: false,
  },
  canAddRepresentativeSigner: false,
  canAddRecipientGroup: false,
  canCollectNotaryPayment: false,
  contactPhoneNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactFirstName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactLastName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactRole: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  collaboratorRole: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  contactTitle: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactOrganizationName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactOrganizationAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  contactShownToSigner: {
    display: "hidden",
    default: false,
    required: false,
  },
  contactAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  collaboratorShownToSigner: {
    display: "hidden",
    default: false,
    required: false,
  },
  collaboratorAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  titleAgency: {
    display: "hidden",
    default: "",
    required: false,
  },
  canAddCollaborators: false,
  hasDefaultContact: false,
  nstPayer: {
    display: "hidden",
    default: "",
    required: false,
  },
  customFees: {
    display: "hidden",
    default: {},
    required: false,
  },
  documentUploader: {
    display: "hidden",
    default: "",
    required: false,
  },
  titleAgencyName: {
    display: "hidden",
    default: "",
    required: false,
  },
  defaultDocPermissions: { witnessRequired: false },
  canSetDocPermissions: { value: false, locked: true },
  canSetDocRequirements: { value: false, locked: true },
  canRequireAttestation: { value: false, locked: false },
  validateDuplicateEmails: false,
  defaultDocRequirement: undefined,
  maxSigners: 1,
  splitBookmarkedPdf: { value: false, locked: false },
  supportedFileTypes: [],
  canRequireWitness: { value: false, locked: true },
  transactionProofRequirements: [
    ProofRequirementFormValues.IAL2,
    ProofRequirementFormValues.CA_SELFIE,
    ProofRequirementFormValues.KBA,
  ],
  placeOrderCapable: false,
  isCollaboratorUser: false,
};

const DEPRECATED_ESIGN_CONFIG: Config = {
  ...SIGN_CONFIG,
  id: "esign",
  name: MESSAGES.esignName,
  description: MESSAGES.esignDescription,
};

export const PROOF_CONFIG: Config = {
  ...DEPRECATED_ESIGN_CONFIG,
  id: "proof",
  name: MESSAGES.proofName,
  description: MESSAGES.proofDescription,
  signerProofRequirements: {
    display: "editable",
    default: [],
    required: false,
  },
  requireProofSigner: true,
  optionalProofSigner: false,
  transactionProofRequirements: DEFAULT_PROOF_REQUIREMENTS,
  validations: {
    ...SIGN_CONFIG.validations,
    validateRecipientDetails: true,
  },
};

const BASE_REAL_SIGN_CONFIG = {
  transactionType: {
    display: "hidden",
    default: "",
    required: false,
  },
  fileNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  loanNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  contactPhoneNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  contactFirstName: {
    display: "editable",
    default: "",
    required: true,
  },
  contactLastName: {
    display: "editable",
    default: "",
    required: true,
  },
  contactEmail: {
    display: "editable",
    default: "",
    required: false,
  },
  contactRole: {
    display: "editable",
    default: undefined,
    required: true,
  },
  contactTitle: {
    display: "editable",
    default: "",
    required: false,
  },
  contactShownToSigner: {
    display: "editable",
    default: true,
    required: false,
  },
  contactAccessToTransaction: {
    display: "hidden",
    default: true,
    required: false,
  },
  emailSubject: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailSignature: {
    display: "hidden",
    default: "",
    required: false,
  },
  signerMessage: {
    display: "editable",
    default: "",
    required: false,
  },
} as const;

export const REAL_ESTATE_SIGN_CONFIG: Config = {
  ...SIGN_CONFIG,
  ...BASE_REAL_SIGN_CONFIG,
  id: "real_estate_sign",
};

const DEPRECATED_REAL_ESTATE_ESIGN_CONFIG: Config = {
  ...DEPRECATED_ESIGN_CONFIG,
  ...BASE_REAL_SIGN_CONFIG,
  id: "real_estate_esign",
};

export const REAL_ESTATE_PROOF_CONFIG: Config = {
  ...PROOF_CONFIG,
  ...BASE_REAL_SIGN_CONFIG,
  id: "real_estate_proof",
};

const REAL_ESTATE_IDENTIFY_CONFIG: Config = {
  ...IDENTIFY_CONFIG,
  id: "real_estate_identify",
  transactionType: {
    display: "hidden",
    default: null,
    required: false,
  },
  fileNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  loanNumber: {
    display: "editable",
    default: "",
    required: false,
  },
};

const NOTARIZATION_CONFIG: Config = {
  id: "notarization",
  name: MESSAGES.notarizationName,
  description: MESSAGES.notarizationDescription,
  validations: {
    validateOrgPayment: true,
    validateDocuments: true,
    validateDocumentSignatures: true,
    validateNSTCompliance: true,
    validateRecipientDetails: true,
  },
  transactionName: {
    display: "editable",
    default: "",
    required: true,
  },
  transactionType: {
    display: "editable",
    default: "",
    required: false,
  },
  titleUnderwriter: {
    display: "hidden",
    default: "",
    required: false,
  },
  propertyAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  recordingLocation: {
    display: "hidden",
    default: "",
    required: false,
  },
  fileNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  loanNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  externalId: {
    display: "editable",
    default: "",
    required: false,
  },
  emailSubject: {
    display: "editable",
    default: "",
    required: false,
  },
  emailMessage: {
    display: "editable",
    default: "",
    required: false,
  },
  emailSignature: {
    display: "editable",
    default: "",
    required: false,
  },
  signerMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  activationDate: {
    display: "editable",
    default: undefined,
    required: false,
  },
  expirationDate: {
    display: "editable",
    default: undefined,
    required: false,
  },
  notaryMeetingTime: {
    display: "editable",
    default: "",
    required: false,
  },
  notaryMeetingDate: {
    display: "editable",
    default: "",
    required: false,
  },
  closerAssigneeId: {
    display: "editable",
    default: undefined,
    required: false,
  },
  notarizeCloserOverride: {
    display: "editable",
    default: undefined,
    required: false,
  },
  credibleWitnessFirstName: {
    display: "editable",
    default: "",
    required: false,
  },
  credibleWitnessMiddleName: {
    display: "editable",
    default: "",
    required: false,
  },
  credibleWitnessLastName: {
    display: "editable",
    default: "",
    required: false,
  },
  credibleWitnessEmail: {
    display: "editable",
    default: "",
    required: false,
  },
  credibleWitnessPhoneNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  notaryNotes: {
    display: "editable",
    default: undefined,
    required: false,
  },
  personallyKnownToNotary: {
    display: "editable",
    default: false,
    required: false,
  },
  payer: {
    display: "editable",
    default: undefined,
    required: false,
  },
  orgRequiredPayment: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  promissoryNote: {
    display: "hidden",
    default: "",
    required: false,
  },
  secondaryIdRequired: {
    display: "editable",
    default: "no",
    required: false,
  },
  ccRecipientEmail: {
    display: "editable",
    default: "",
    required: false,
  },
  signerFirstName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerMiddleName: {
    display: "editable",
    default: "",
    required: false,
  },
  signerLastName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerEmail: {
    display: "editable",
    default: "",
    required: true,
  },
  signerSigningRequirement: {
    display: "editable",
    default: SigningRequirementEnum.NOTARIZATION,
    required: false,
  },
  signerCountryCode: {
    display: "editable",
    default: "",
    required: false,
  },
  signerPhoneNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  signerAddress: {
    display: "editable",
    default: {},
    required: false,
  },
  signerProofRequirements: {
    display: "editable",
    default: [],
    required: false,
  },
  signerSharedInboxEmail: {
    display: "editable",
    default: "",
    required: true,
  },
  signerVestingType: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  requireProofSigner: false,
  optionalProofSigner: false,
  smsAuthRequired: {
    display: "editable",
    default: false,
    required: false,
  },
  signingOrderEnabled: {
    display: "editable",
    default: false,
    required: false,
  },
  canAddRepresentativeSigner: true,
  canAddRecipientGroup: true,
  canCollectNotaryPayment: true,
  contactPhoneNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactFirstName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactLastName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactRole: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  collaboratorRole: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  contactTitle: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactOrganizationName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactOrganizationAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  contactShownToSigner: {
    display: "hidden",
    default: false,
    required: false,
  },
  contactAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  collaboratorShownToSigner: {
    display: "hidden",
    default: false,
    required: false,
  },
  collaboratorAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  titleAgency: {
    display: "hidden",
    default: "",
    required: false,
  },
  canAddCollaborators: false,
  hasDefaultContact: false,
  nstPayer: {
    display: "editable",
    default: "",
    required: false,
  },
  customFees: {
    display: "editable",
    default: {},
    required: false,
  },
  documentUploader: {
    display: "editable",
    default: "",
    required: true,
  },
  titleAgencyName: {
    display: "hidden",
    default: "",
    required: false,
  },
  defaultDocPermissions: { witnessRequired: false },
  defaultDocRequirement: DocumentRequirementEnum.NOTARIZATION,
  canSetDocPermissions: { value: true, locked: false },
  canSetDocRequirements: { value: true, locked: false },
  canRequireAttestation: { value: false, locked: false },
  validateDuplicateEmails: false,
  splitBookmarkedPdf: { value: false, locked: false },
  supportedFileTypes: SUPPORTED_FILE_EXTENSIONS,
  transactionProofRequirements: DEFAULT_PROOF_REQUIREMENTS,
  canRequireWitness: { value: true, locked: true },
  placeOrderCapable: false,
  isCollaboratorUser: false,
};

const SIMPLE_NOTARIZATION_CONFIG: Config = {
  ...NOTARIZATION_CONFIG,
  id: "simple_notarization",
  transactionType: {
    display: "hidden",
    default: "",
    required: false,
  },
  secondaryIdRequired: {
    display: "hidden",
    default: "no",
    required: false,
  },
  externalId: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailSubject: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailSignature: {
    display: "hidden",
    default: "",
    required: false,
  },
  signerMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  activationDate: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  expirationDate: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  notaryMeetingTime: {
    display: "hidden",
    default: "",
    required: false,
  },
  notaryMeetingDate: {
    display: "hidden",
    default: "",
    required: false,
  },
  closerAssigneeId: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  notarizeCloserOverride: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  credibleWitnessFirstName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessMiddleName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessLastName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessPhoneNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  notaryNotes: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  personallyKnownToNotary: {
    display: "hidden",
    default: false,
    required: false,
  },
  signingOrderEnabled: {
    display: "hidden",
    default: false,
    required: false,
  },
  canAddRepresentativeSigner: true,
  canAddRecipientGroup: false,
  canCollectNotaryPayment: true,
};
// Based on refinance/purchase_buyer_loan
const BASE_MORTGAGE_CONFIG: Omit<Config, "id" | "name" | "description"> = {
  validations: {
    validateOrgPayment: true,
    validateDocuments: true,
    validateDocumentSignatures: true,
    validateNSTCompliance: false,
    validateRecipientDetails: true,
    validateENote: false,
  },
  transactionName: {
    display: "hidden",
    default: "",
    required: false,
  },
  transactionType: {
    display: "readonly",
    default: "",
    required: true,
  },
  titleUnderwriter: {
    display: "editable",
    default: "",
    required: true,
  },
  promissoryNote: {
    display: "hidden",
    default: "",
    required: false,
  },
  propertyAddress: {
    display: "readonly",
    default: undefined,
    required: true,
  },
  recordingLocation: {
    display: "readonly",
    default: "",
    required: true,
  },
  fileNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  loanNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  externalId: {
    display: "editable",
    default: "",
    required: false,
  },
  emailSubject: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailMessage: {
    display: "hidden",
    default: "",
    required: false,
  },
  emailSignature: {
    display: "hidden",
    default: "",
    required: false,
  },
  signerMessage: {
    display: "editable",
    default: "",
    required: false,
  },
  activationDate: {
    display: "editable",
    default: undefined,
    required: false,
  },
  expirationDate: {
    display: "editable",
    default: undefined,
    required: true,
  },
  notaryMeetingTime: {
    display: "editable",
    default: "",
    required: false,
  },
  notaryMeetingDate: {
    display: "editable",
    default: "",
    required: true,
  },
  closerAssigneeId: {
    display: "editable",
    default: undefined,
    required: false,
  },
  notarizeCloserOverride: {
    display: "editable",
    default: undefined,
    required: false,
  },
  credibleWitnessFirstName: {
    display: "editable",
    default: undefined,
    required: false,
  },
  credibleWitnessMiddleName: {
    display: "editable",
    default: undefined,
    required: false,
  },
  credibleWitnessLastName: {
    display: "editable",
    default: undefined,
    required: false,
  },
  credibleWitnessEmail: {
    display: "editable",
    default: undefined,
    required: false,
  },
  credibleWitnessPhoneNumber: {
    display: "editable",
    default: undefined,
    required: false,
  },
  notaryNotes: {
    display: "editable",
    default: undefined,
    required: false,
  },
  personallyKnownToNotary: {
    display: "editable",
    default: false,
    required: false,
  },
  payer: {
    display: "editable",
    default: undefined,
    required: false,
  },
  orgRequiredPayment: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  secondaryIdRequired: {
    display: "editable",
    default: "no",
    required: false,
  },
  ccRecipientEmail: {
    display: "editable",
    default: "",
    required: false,
  },
  signerFirstName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerMiddleName: {
    display: "editable",
    default: "",
    required: false,
  },
  signerLastName: {
    display: "editable",
    default: "",
    required: true,
  },
  signerEmail: {
    display: "editable",
    default: "",
    required: true,
  },
  signerSigningRequirement: {
    display: "editable",
    default: SigningRequirementEnum.NOTARIZATION,
    required: false,
  },
  signerCountryCode: {
    display: "editable",
    default: "",
    required: false,
  },
  signerPhoneNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  signerAddress: {
    display: "editable",
    default: {},
    required: false,
  },
  signerProofRequirements: {
    display: "editable",
    default: [],
    required: false,
  },
  signerSharedInboxEmail: {
    display: "editable",
    default: "",
    required: true,
  },
  signerVestingType: {
    display: "editable",
    default: VestingTypesEnum.BORROWER,
    required: true,
  },
  requireProofSigner: false,
  optionalProofSigner: false,
  smsAuthRequired: {
    display: "editable",
    default: false,
    required: false,
  },
  signingOrderEnabled: {
    display: "editable",
    default: false,
    required: false,
  },
  canAddRepresentativeSigner: true,
  canAddRecipientGroup: true,
  canCollectNotaryPayment: false,
  contactPhoneNumber: {
    display: "editable",
    default: "",
    required: false,
  },
  contactFirstName: {
    display: "editable",
    default: "",
    required: true,
  },
  contactLastName: {
    display: "editable",
    default: "",
    required: true,
  },
  contactEmail: {
    display: "editable",
    default: "",
    required: true,
  },
  contactRole: {
    display: "editable",
    default: undefined,
    required: true,
  },
  collaboratorRole: {
    display: "editable",
    default: OrganizationTransactionContactRoleType.TITLE_AGENT,
    required: true,
  },
  contactTitle: {
    display: "editable",
    default: "",
    required: true,
  },
  contactOrganizationName: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactOrganizationAddress: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  titleAgency: {
    display: "hidden",
    default: "",
    required: false,
  },
  contactShownToSigner: {
    display: "editable",
    default: true,
    required: false,
  },
  contactAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  collaboratorShownToSigner: {
    display: "editable",
    default: true,
    required: false,
  },
  collaboratorAccessToTransaction: {
    display: "hidden",
    default: false,
    required: false,
  },
  canAddCollaborators: false,
  hasDefaultContact: false,
  nstPayer: {
    display: "hidden",
    default: "",
    required: false,
  },
  customFees: {
    display: "hidden",
    default: {},
    required: false,
  },
  documentUploader: {
    display: "editable",
    default: "",
    required: true,
  },

  titleAgencyName: {
    display: "hidden",
    default: "",
    required: false,
  },
  defaultDocPermissions: { witnessRequired: false },
  defaultDocRequirement: DocumentRequirementEnum.NOTARIZATION,
  canSetDocPermissions: { value: true, locked: false },
  canSetDocRequirements: { value: true, locked: false },
  canRequireAttestation: { value: false, locked: true },
  validateDuplicateEmails: false,
  splitBookmarkedPdf: { value: false, locked: false },
  supportedFileTypes: SUPPORTED_FILE_EXTENSIONS,
  transactionProofRequirements: DEFAULT_PROOF_REQUIREMENTS,
  canRequireWitness: { value: true, locked: true },
  placeOrderCapable: true,
  isCollaboratorUser: false,
};

const BASE_MORTGAGE_HYBRID_CONFIG: Omit<Config, "id" | "name" | "description"> = {
  ...BASE_MORTGAGE_CONFIG,
  validateDuplicateEmails: true,
  defaultDocRequirement: DocumentRequirementEnum.ESIGN,
  credibleWitnessFirstName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessMiddleName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessLastName: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessEmail: {
    display: "hidden",
    default: "",
    required: false,
  },
  credibleWitnessPhoneNumber: {
    display: "hidden",
    default: "",
    required: false,
  },
  personallyKnownToNotary: {
    display: "hidden",
    default: false,
    required: false,
  },
  notarizeCloserOverride: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  closerAssigneeId: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  signerSigningRequirement: {
    display: "hidden",
    default: undefined,
    required: false,
  },
  validations: {
    ...BASE_MORTGAGE_CONFIG.validations,
    validateENote: true,
  },
};

const COLLABORATOR_CONFIG: Partial<Config> = {
  isCollaboratorUser: true,
  propertyAddress: {
    display: "readonly",
    default: undefined,
    required: false,
  },
  signerFirstName: {
    display: "readonly",
    default: "",
    required: true,
  },
  signerMiddleName: {
    display: "readonly",
    default: "",
    required: false,
  },
  signerLastName: {
    display: "readonly",
    default: "",
    required: true,
  },
  signerEmail: {
    display: "readonly",
    default: "",
    required: true,
  },
  signerSigningRequirement: {
    display: "readonly",
    default: SigningRequirementEnum.NOTARIZATION,
    required: false,
  },
  signerCountryCode: {
    display: "readonly",
    default: "",
    required: false,
  },
  signerPhoneNumber: {
    display: "readonly",
    default: "",
    required: false,
  },
  signerAddress: {
    display: "hidden",
    default: {},
    required: false,
  },
  secondaryIdRequired: {
    display: "readonly",
    default: "",
    required: false,
  },
  smsAuthRequired: {
    display: "readonly",
    default: false,
    required: false,
  },
  validations: {
    validateStateEligibility: true,
  },
  titleUnderwriter: {
    display: "editable",
    default: "",
    required: true,
  },
};

// Base config is collab off
export const LENDER_REFINANCE_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "lender_refinance",
  name: MESSAGES.refinanceName,
  transactionType: {
    display: "readonly",
    default: ALL_LENDER_TRANSACTION_TYPES.REFINANCE,
    required: true,
  },
  promissoryNote: {
    display: "editable",
    default: "",
    required: true,
  },
  validations: {
    ...BASE_MORTGAGE_CONFIG.validations,
    validateENote: true,
  },
  titleAgencyName: {
    display: "readonly",
    default: "",
    required: false,
  },
};

export const LENDER_REFINANCE_COLLAB_CONFIG: Config = {
  ...LENDER_REFINANCE_CONFIG,
  id: "lender_refinance_collab",
  titleUnderwriter: {
    display: "editable",
    default: "",
    required: false,
  },
  contactOrganizationName: {
    display: "editable",
    default: "",
    required: true,
  },
  contactOrganizationAddress: {
    display: "editable",
    default: undefined,
    required: false,
  },
  titleAgency: {
    display: "editable",
    default: "",
    required: true,
  },
  canAddCollaborators: true,
  hasDefaultContact: true,
};

// Collaborator looking at LIT refinance
export const LENDER_REFINANCE_COLLABORATOR_CONFIG: Config = {
  ...LENDER_REFINANCE_CONFIG,
  ...COLLABORATOR_CONFIG,
  validations: {
    ...LENDER_REFINANCE_CONFIG.validations,
    ...COLLABORATOR_CONFIG.validations,
  },
};

// Hybrid has no collab setting
export const HYBRID_REFINANCE_CONFIG: Config = {
  ...BASE_MORTGAGE_HYBRID_CONFIG,
  id: "hybrid_refinance",
  name: MESSAGES.hybridRefinanceName,
  transactionType: {
    display: "readonly",
    default: LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_REFINANCE,
    required: true,
  },
  recordingLocation: {
    display: "readonly",
    default: "",
    required: false,
  },
  titleUnderwriter: {
    display: "editable",
    default: "",
    required: false,
  },
  propertyAddress: {
    display: "readonly",
    default: undefined,
    required: false,
  },
  contactAccessToTransaction: {
    display: "editable",
    default: false,
    required: false,
  },
  canRequireWitness: { value: false, locked: true },
};

// If collab is on, we'll still add the collaborator section
export const HYBRID_REFINANCE_COLLAB_CONFIG: Config = {
  ...HYBRID_REFINANCE_CONFIG,
  id: "hybrid_refinance_collab",
  collaboratorAccessToTransaction: {
    display: "editable",
    default: true,
    required: false,
  },
  contactOrganizationName: {
    display: "editable",
    default: "",
    required: true,
  },
  contactOrganizationAddress: {
    display: "editable",
    default: undefined,
    required: false,
  },
  titleAgency: {
    display: "editable",
    default: "",
    required: true,
  },
  canAddCollaborators: true,
  hasDefaultContact: true,
};

export const PURCHASE_BUYER_LOAN_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "purchase_buyer_loan",
  name: MESSAGES.purchaseBuyerLoanName,
  transactionType: {
    display: "readonly",
    default: ALL_LENDER_TRANSACTION_TYPES.PURCHASE_BUYER_LOAN,
    required: true,
  },
  promissoryNote: {
    display: "editable",
    default: "",
    required: true,
  },
  titleAgencyName: {
    display: "readonly",
    default: "",
    required: false,
  },
  validations: {
    ...BASE_MORTGAGE_CONFIG.validations,
    validateENote: true,
  },
};

export const PURCHASE_BUYER_LOAN_COLLAB_CONFIG: Config = {
  ...PURCHASE_BUYER_LOAN_CONFIG,
  id: "purchase_buyer_loan_collab",
  contactOrganizationName: {
    display: "editable",
    default: "",
    required: true,
  },
  contactOrganizationAddress: {
    display: "editable",
    default: undefined,
    required: false,
  },
  titleAgency: {
    display: "editable",
    default: "",
    required: true,
  },
  titleUnderwriter: {
    display: "editable",
    default: "",
    required: false,
  },
  canAddCollaborators: true,
  hasDefaultContact: true,
};

// Collaborator looking at LIT purchase buyer loan
export const PURCHASE_BUYER_LOAN_COLLABORATOR_CONFIG: Config = {
  ...PURCHASE_BUYER_LOAN_CONFIG,
  ...COLLABORATOR_CONFIG,
  validations: {
    ...PURCHASE_BUYER_LOAN_CONFIG.validations,
    ...COLLABORATOR_CONFIG.validations,
  },
};

export const HYBRID_PURCHASE_BUYER_LOAN_CONFIG: Config = {
  ...BASE_MORTGAGE_HYBRID_CONFIG,
  id: "hybrid_purchase_buyer_loan",
  name: MESSAGES.hybridPurchaseBuyerName,
  transactionType: {
    display: "readonly",
    default: LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_BUYER_LOAN,
    required: true,
  },
  recordingLocation: {
    display: "readonly",
    default: "",
    required: false,
  },
  titleUnderwriter: {
    display: "editable",
    default: "",
    required: false,
  },
  propertyAddress: {
    display: "readonly",
    default: undefined,
    required: false,
  },
  contactAccessToTransaction: {
    display: "editable",
    default: false,
    required: false,
  },
  canRequireWitness: { value: false, locked: true },
};

export const HYBRID_PURCHASE_BUYER_LOAN_COLLAB_CONFIG: Config = {
  ...HYBRID_PURCHASE_BUYER_LOAN_CONFIG,
  id: "hybrid_purchase_buyer_loan_collab",
  collaboratorAccessToTransaction: {
    display: "editable",
    default: true,
    required: false,
  },
  contactOrganizationName: {
    display: "editable",
    default: "",
    required: true,
  },
  contactOrganizationAddress: {
    display: "editable",
    default: undefined,
    required: false,
  },
  titleAgency: {
    display: "editable",
    default: "",
    required: true,
  },
  canAddCollaborators: true,
  hasDefaultContact: true,
};

// TODO unclear if some of these should be hidden vs disabled
export const TRAILING_DOCS_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "trailing_docs",
  name: MESSAGES.trailingDocsName,
  transactionType: {
    display: "readonly",
    default: TRANSACTION_TYPE_TRAILING_DOCS,
    required: true,
  },
  recordingLocation: {
    display: "readonly",
    default: "",
    required: false,
  },

  titleUnderwriter: {
    display: "hidden",
    default: "",
    required: false,
  },
  propertyAddress: {
    display: "readonly",
    default: undefined,
    required: false,
  },
};

export const HYBRID_TRAILING_DOCS_CONFIG: Config = {
  ...BASE_MORTGAGE_HYBRID_CONFIG,
  id: "hybrid_trailing_docs",
  name: MESSAGES.hybridTrailingDocsName,
  transactionType: {
    display: "readonly",
    default: TRANSACTION_TYPE_HYBRID_TRAILING_DOCS,
    required: true,
  },
  recordingLocation: {
    display: "readonly",
    default: "",
    required: false,
  },

  titleUnderwriter: {
    display: "hidden",
    default: "",
    required: false,
  },
  propertyAddress: {
    display: "readonly",
    default: undefined,
    required: false,
  },
  contactAccessToTransaction: {
    display: "editable",
    default: false,
    required: false,
  },
  canRequireWitness: { value: false, locked: true },
};

// HELOCs do not have collab
export const LENDER_HELOC_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "lender_heloc",
  name: MESSAGES.helocName,
  transactionType: {
    display: "readonly",
    default: TRANSACTION_TYPE_HELOC,
    required: true,
  },
  titleUnderwriter: {
    display: "hidden",
    default: null,
    required: false,
  },
  promissoryNote: {
    display: "editable",
    default: null,
    required: false,
  },
  validations: {
    ...BASE_MORTGAGE_CONFIG.validations,
    validateENote: true,
  },
};

export const TITLE_HELOC_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "title_heloc",
  name: MESSAGES.helocName,
  transactionType: {
    display: "readonly",
    default: TRANSACTION_TYPE_HELOC,
    required: true,
  },

  titleUnderwriter: {
    display: "hidden",
    default: null,
    required: false,
  },
};

// Loan mods do not have collab
export const LENDER_LOAN_MOD_BORROWER_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "lender_loan_mod_borrower",
  name: MESSAGES.loanModificationLenderName,
  transactionType: {
    display: "readonly",
    default: TRANSACTION_TYPE_LOAN_MOD_BORROWER,
    required: true,
  },
  titleUnderwriter: {
    display: "hidden",
    default: null,
    required: false,
  },
  loanNumber: {
    display: "editable",
    default: "",
    required: true,
  },
  titleAgencyName: {
    display: "readonly",
    default: "",
    required: false,
  },
};

export const TITLE_LOAN_MOD_BORROWER_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "title_loan_mod_borrower",
  name: MESSAGES.loanModificationTitleName,
  transactionType: {
    display: "readonly",
    default: TRANSACTION_TYPE_LOAN_MOD_BORROWER,
    required: true,
  },
  titleUnderwriter: {
    display: "hidden",
    default: null,
    required: false,
  },
  fileNumber: {
    display: "editable",
    default: "",
    required: true,
  },
};

export const OTHER_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "other",
  name: MESSAGES.otherName,
  transactionType: {
    display: "readonly",
    default: TRANSACTION_TYPE_OTHER,
    required: true,
  },
  recordingLocation: {
    display: "hidden",
    default: "",
    required: false,
  },
  titleUnderwriter: {
    display: "hidden",
    default: null,
    required: false,
  },

  propertyAddress: {
    display: "editable",
    default: undefined,
    required: false,
  },
};

// Title doesn't have collab
export const PURCHASE_SELLER_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "purchase_seller",
  name: MESSAGES.purchaseSellerName,
  transactionType: {
    display: "readonly",
    default: TITLE_MORTGAGE_TRANSACTION_TYPES.PURCHASE_SELLER,
    required: true,
  },
  fileNumber: {
    display: "editable",
    default: "",
    required: true,
  },
  titleUnderwriter: {
    display: "editable",
    default: "",
    required: false,
  },
};

export const PURCHASE_BUYER_CONFIG: Config = {
  ...BASE_MORTGAGE_CONFIG,
  id: "purchase_buyer",
  name: MESSAGES.purchaseBuyerCashName,
  transactionType: {
    display: "readonly",
    default: TITLE_MORTGAGE_TRANSACTION_TYPES.PURCHASE_BUYER_CASH,
    required: true,
  },
  fileNumber: {
    display: "editable",
    default: "",
    required: true,
  },
  titleUnderwriter: {
    display: "editable",
    default: "",
    required: false,
  },
};

// long term will be fetched from db, can add configs here as needed
// (only needed when specifying config by id in query param)
export const ALL_CONFIGS = [
  SIGN_CONFIG,
  PROOF_CONFIG,
  REAL_ESTATE_SIGN_CONFIG,
  REAL_ESTATE_PROOF_CONFIG,
  SIMPLE_NOTARIZATION_CONFIG,
  NOTARIZATION_CONFIG,
  CERTIFY_CONFIG,
  IDENTIFY_CONFIG,
  REAL_ESTATE_IDENTIFY_CONFIG,
  DEPRECATED_ESIGN_CONFIG,
  DEPRECATED_REAL_ESTATE_ESIGN_CONFIG,
];

export const BUSINESS_CONFIG_IDS = {
  ESIGN: DEPRECATED_ESIGN_CONFIG.id,
  SIGN: SIGN_CONFIG.id,
  PROOF: PROOF_CONFIG.id,
  NOTARIZATION: NOTARIZATION_CONFIG.id,
  IDENTIFY: IDENTIFY_CONFIG.id,
};

export const REAL_ESTATE_ESIGN_CONFIG_IDS = {
  ESIGN: DEPRECATED_REAL_ESTATE_ESIGN_CONFIG.id,
  SIGN: REAL_ESTATE_SIGN_CONFIG.id,
  PROOF: REAL_ESTATE_PROOF_CONFIG.id,
  IDENTIFY: REAL_ESTATE_IDENTIFY_CONFIG.id,
};

// TODO: remove BIZ-6580
// BIZ experiment to present biz pro odn users with simplified notarization form
function useSimpleTransactionForm(
  transactionVariant: OrganizationTransactionVariant | null,
  organization: SimpleTransactionOrganization,
): boolean {
  const newBusinessODNOrg =
    organization.activeTier.tier === Tier.BUSINESS_PRO_ODN &&
    isAfter(organization.createdAt, new Date(2024, 9, 21));

  const requiredExperimentConditions =
    newBusinessODNOrg && transactionVariant === OrganizationTransactionVariant.NOTARIZATION;

  return useSimpleTransactionCreationV3({ skip: !requiredExperimentConditions });
}

export function useGetConfigFromVariant(
  transactionVariant: OrganizationTransactionVariant | null,
  organization: SimpleTransactionOrganization,
) {
  const currentSubdomain = AppSubdomains[CURRENT_PORTAL];
  const isRealPortal =
    currentSubdomain === AppSubdomains.title_agency || currentSubdomain === AppSubdomains.lender;
  const simpleTransactionCreationForm = useSimpleTransactionForm(transactionVariant, organization);
  const signTransactionsEnabled = useSignTransactionsEnabled(); // TODO: BIZ-7039, remove sign feature flag

  switch (transactionVariant) {
    case OrganizationTransactionVariant.ESIGN:
      if (!signTransactionsEnabled) {
        return isRealPortal ? DEPRECATED_REAL_ESTATE_ESIGN_CONFIG : DEPRECATED_ESIGN_CONFIG;
      }
      return isRealPortal ? REAL_ESTATE_SIGN_CONFIG : SIGN_CONFIG;
    case OrganizationTransactionVariant.PROOF:
      return isRealPortal ? REAL_ESTATE_PROOF_CONFIG : PROOF_CONFIG;
    case OrganizationTransactionVariant.NOTARIZATION:
      if (simpleTransactionCreationForm) {
        return SIMPLE_NOTARIZATION_CONFIG;
      }
      return NOTARIZATION_CONFIG;
    case OrganizationTransactionVariant.VERIFICATION_OF_FACT:
      return NOTARIZATION_CONFIG;
    case OrganizationTransactionVariant.CERTIFY:
      return CERTIFY_CONFIG;
    case OrganizationTransactionVariant.IDENTIFY:
      return isRealPortal ? REAL_ESTATE_IDENTIFY_CONFIG : IDENTIFY_CONFIG;
    default:
      return null;
  }
}

// once we do the create transaction "tiles" project we won't need this helper function
export function useGetConfigId(
  transactionVariant: OrganizationTransactionVariant | null,
  organization: SimpleTransactionOrganization,
): string | null {
  const config = useGetConfigFromVariant(transactionVariant, organization);
  return config ? config.id : null;
}
